import { useQuery } from '@apollo/client';
import { DeskCdOffering } from '@bakerweb/models';
import { useEffect, useState } from 'react';
import { GET_DESK_CD_OFFERINGS } from '../graphql/queries/getDeskCdOfferings';

export const useGetCdOfferings = () => {
  const [deskCdOfferings, setCdOfferings] = useState<DeskCdOffering[]>([]);
  const { loading, error, data, refetch } = useQuery(GET_DESK_CD_OFFERINGS, {
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data && data.deskCdOfferings) {
      setCdOfferings(data.deskCdOfferings);
    }
  }, [data]);

  return { loading, error, deskCdOfferings, refetch };
};
