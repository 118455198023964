import { motion } from 'framer-motion';
import { Box, BoxProps } from '@mui/material';
import { varMediumClick, varSmallClick } from './variants';

interface ButtonAnimateProps extends BoxProps {
  mediumClick?: boolean;
}

export function ButtonAnimate({ mediumClick = false, children, sx, ...other }: ButtonAnimateProps) {
  return (
    <Box sx={{ display: 'inline-flex', ...sx }} {...other}>
      <motion.div whileTap="tap" whileHover="hover" variants={mediumClick ? varMediumClick : varSmallClick}>
        {children}
      </motion.div>
    </Box>
  );
}
