import { gql } from '@apollo/client';

export const GET_DESK_CD_OFFERINGS = gql`
  query DeskCdOfferings {
    deskCdOfferings {
      publishUser
      cusip
      issuer
      coupon
      spread
      maturity
      pays
      certNumber
      settle
      restrictions
      dateCreated
      instType
      status
      fedId
      seller
      disclosure
      financial
    }
  }
`;
