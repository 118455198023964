import { MotionContainer } from '@bakerweb/components';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { m } from 'framer-motion';
import type { ReactNode } from 'react';
import { useUserPermissions } from '@bakerweb/client-services';
import { useHasPermission } from './useHasPermission';
import { useLocation } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

export const PermissionsGuard = ({ children }: Props) => {
  const { loading } = useUserPermissions();
  const { pathname } = useLocation();
  const { hasPermissionToViewPath } = useHasPermission();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (!hasPermissionToViewPath(pathname)) {
    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div>
          <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
        </m.div>
      </Container>
    );
  }

  return <>{children}</>;
};
