import { selectedPortfolioState, userPortfolioState } from '@bakerweb/client-state';
import { Logo, MHidden, NavSection, Scrollbar } from '@bakerweb/components';
import { useCollapseDrawer } from '@bakerweb/theme';
import {
  Box,
  CircularProgress,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClientSidebarConfig } from './ClientSidebarConfig';

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export function ClientDashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const portfolios = useRecoilValue(userPortfolioState);
  const userPortfolios = useMemo(() => portfolios, [portfolios]);
  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const [selectedPortfolio, setSelectedPortfolio] = useRecoilState(selectedPortfolioState);
  const [loading, setLoading] = useState(true);
  const clientSidebarConfig = useClientSidebarConfig();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (userPortfolios.length > 0) {
      if (!selectedPortfolio) {
        setSelectedPortfolio(userPortfolios[0]);
      }
      setLoading(false);
    }
  }, [userPortfolios, setSelectedPortfolio, selectedPortfolio]);

  const handlePortfolioChange = (event: SelectChangeEvent<string>) => {
    const portfolioId = event.target.value;
    const portfolio = userPortfolios.find((p) => p.portfolioId === portfolioId);
    setSelectedPortfolio(portfolio);
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo sx={{ width: 150, height: 70 }} />
          </Box>
        </Stack>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="portfolio-select-label">Select Portfolio</InputLabel>
            <Select
              labelId="portfolio-select-label"
              value={selectedPortfolio?.portfolioId}
              onChange={handlePortfolioChange}
              label="Select Portfolio"
            >
              {userPortfolios.map((permission) => (
                <MenuItem key={permission.portfolioId} value={permission.portfolioId}>
                  {permission.portfolioCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      <NavSection navConfig={clientSidebarConfig} isShow={!isCollapse} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
