import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Card, FormControl, Grid, Stack } from '@mui/material';
import { Iconify } from '../iconify/Iconify';
import { DataGridPro, GridColDef, GridRowClassNameParams, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { FormProvider, RHFTextField } from '@bakerweb/forms';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreatePledgee, useGetPledgeCodes } from '@bakerweb/client-services';
import { Pledge } from '@bakerweb/models';

const columns: GridColDef[] = [
  {
    field: 'pledgeCode',
    flex: 1,
    headerClassName: 'pledge-table-header',
    headerName: 'Pledge Code',
    type: 'string',
    editable: false
  },
  {
    field: 'pledgeeName',
    flex: 1,
    headerClassName: 'pledge-table-header',
    headerName: 'Pledgee Name',
    type: 'string',
    editable: false
  }
];

type NewPledgeProps = {
  pledgeCode: string;
  pledgeeName: string;
};

export function PledgeCodesForm() {
  const [formActive, setFormActive] = useState(false);
  const [newRowId, setNewRowId] = useState(0);
  const { loading, pledgeCodes, refetch } = useGetPledgeCodes();
  const { createPledgee } = useCreatePledgee();

  const NewPledgeSchema = Yup.object().shape({
    pledgeCode: Yup.string().trim().required('Pledge code is required.'),
    pledgeeName: Yup.string().trim().required('Pledge name is required.')
  });

  const defaultValues = {
    pledgeCode: '',
    pledgeeName: ''
  };

  const methods = useForm<NewPledgeProps>({
    resolver: yupResolver(NewPledgeSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = methods;

  const onSubmit = async (data: NewPledgeProps) => {
    try {
      await createPledgee(data);
      reset(defaultValues);
      togglePledgeCode();
      await refetch();
    } catch (error) {
      console.error('Error creating pledge code:', error);
    }
  };

  const togglePledgeCode = () => {
    setFormActive(!formActive);
  };

  const handleRowClassname = (params: GridRowClassNameParams) => {
    return params.row.id === newRowId ? 'Mui-selected' : '';
  };

  return (
    <>
      <Typography id="Manage Pledge Codes" variant="h6" component="h2" sx={{ mb: 2 }}>
        Manage Pledge Codes
      </Typography>
      <Stack>
        {!formActive && (
          <Stack spacing={2} direction="row" alignItems={{ xs: 'flex-start', md: 'center' }}>
            <FormControl sx={{ mt: 2, mb: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Iconify icon="eva:plus-outline" />}
                onClick={togglePledgeCode}
              >
                Add Pledge Code
              </Button>
            </FormControl>
          </Stack>
        )}
        {formActive && (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3}>
                <RHFTextField name="pledgeCode" label="Pledge Code" />
                <RHFTextField name="pledgeeName" label="Pledgee Name" />
              </Stack>
              <Stack direction="row" spacing={1.5} sx={{ mt: 3, mb: 3 }}>
                <FormControl fullWidth>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    startIcon={<Iconify icon="eva:minus-circle-outline" />}
                    onClick={togglePledgeCode}
                  >
                    Cancel
                  </Button>
                </FormControl>
                <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
                  Add Pledge Code
                </LoadingButton>
              </Stack>
            </Grid>
          </FormProvider>
        )}
      </Stack>
      <Stack>
        <Card sx={{ width: '100%', mt: 4, p: 4 }}>
          <DataGridPro
            loading={loading}
            getRowId={(row: Pledge) => row.recId}
            slots={{
              toolbar: () => <GridToolbarQuickFilter />
            }}
            slotProps={{
              toolbar: { debounceMs: 100, autoFocus: true }
            }}
            autoHeight
            rows={pledgeCodes}
            columns={columns}
            getRowClassName={handleRowClassname}
          />
        </Card>
      </Stack>
    </>
  );
}
